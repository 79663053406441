import React, { Component } from 'react';
import './proficencies.css';
// import Languages from '../languages.jsx';

class proficencies extends Component {
    render() {
        return (
            <div>
                <h1>Proficiencies</h1>
                <p>
                    Here are a few of my key <span>skills and abilities</span>: Java, C, C++ Verilog, JavaScript, HTML, CSS, React, Node.js, Express, MongoDB, Git, and more.
                </p>
                {/* <div className="grid-container-prof">
                    <div className="grid-items-prof">
                        <Languages name="C"/>
                        <Languages name="C++"/>
                        <Languages name="Java"/>
                        <Languages name="Verilog"/>
                        <Languages name="HTML"/>
                        <Languages name="CSS"/>
                        <Languages name="JavaScript"/>
                        <Languages name="Express"/>
                        <Languages name="MongoDB"/>

                    </div>
                </div> */}
            </div>
        );
    }
}

export default proficencies;