import React, { Component } from 'react';
import './resume.css';

class resume extends Component {
    render() {
        return (
                 <iframe className="resume" title="resume"src="https://drive.google.com/file/d/1HpNagpjngn_VOsyy6Mb1MTFhrdxyYzZr/preview"></iframe>
        );
    }
}

export default resume;
