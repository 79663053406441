import React, { Component, useState } from 'react';
import './home.css';
import Nav from '../../components/Navigation/nav.jsx';
import Footer from '../../components/Footer/footer.jsx';
import About from '../../components/About/about.jsx';
import Snippets from '../../components/Statistics/snippets.jsx';
import Connect from '../../components/Connect/connect.jsx';
import Proficencies from '../../components/Proficiencies/proficencies';
import Work from '../../components/Selected Work/work';
import $ from 'jquery';
import { motion } from "framer-motion";
import AnimatedText from "../../components/Title Name/AnimatedText";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


function Name() {
    const [replay] = useState(true);
    // Placeholder text data, as if from API
    const placeholderText = [
        { type: "heading1", text: "Spencer" },
        { type: "heading1", text: "Scher" }
    ];

    const container = {
        visible: {
            transition: {
                staggerChildren: 0.025
            }
        }
    };


    return (
        <motion.div
            className="App"
            initial="hidden"
            // animate="visible"
            animate={replay ? "visible" : "hidden"}
            variants={container}
        >
            <div className="container">
                {placeholderText.map((item, index) => {
                    return <AnimatedText {...item} key={index} />;
                })}
            </div>
        </motion.div>
    );
}

function onChange(value) {
    console.log('Captcha value:', value);
}



class home extends Component {
    render() {
        return (
            <div id="page">
                <div id="specialName">
                    <Nav id="navMain" />
                </div>
                <div className="grid-container">
                    <div className="grid-items">
                        {/* <h1 id="name">Spencer<br></br>Scher</h1> */}
                        <Name />
                        <About id="about" />
                        <Snippets className="item" />
                        <Proficencies className="item" />
                        <Connect className="item" />
                        <Work className="item" />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default home;