import React, { Component, useState } from 'react';
import './about.css';


class about extends Component {
    
    render() {
        return (
            <div id="about">
                {/* <Name id="fullName"/> */}
                <h1 id="about">About Me</h1>
                <p>
                    I'm an engineer, developer and entrepreneur based in <span>Washington, DC</span>. I am passionate about developing new products and expanding my skillsets with every new project.
                </p>
            </div>
        );
    }
}

export default about;
