import React, { Component } from 'react';
import './button.css';
import { ReactComponent as Github } from '../../assets/githubWhite.svg';

class button extends Component {
    render() {
        return (
            <div id="buttons">
                <Github />
                <div className="text">{this.props.name}</div>
            </div>
        );
    }
}

export default button;