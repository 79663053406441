import React, { Component, useState } from 'react';
import '../Home/home.css';
import Nav from '../../components/Navigation/nav.jsx';
import Footer from '../../components/Footer/footer.jsx';
import About from '../../components/About/about.jsx';
import Snippets from '../../components/Statistics/snippets.jsx';
import Connect from '../../components/Connect/connect.jsx';
import Proficencies from '../../components/Proficiencies/proficencies';
import Work from '../../components/Selected Work/work';
import $ from 'jquery';
import { motion } from "framer-motion";
import AnimatedText from "../../components/Title Name/AnimatedText";
import Resume from '../../components/Resume/resume';
import './resume.css';



class ResumePage extends Component {
    render() {
        return (
            <div id="page">
                <div id="specialName">
                    <Nav id="navMain" />
                </div>
                <div id="resumeMain">
                    <Resume />
                </div>
                <Footer />
            </div>
        );
    }
}

export default ResumePage;