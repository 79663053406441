import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './nav.css';

class nav extends Component {
    render() {
        return (
            <div className="navBar">
                {/* create a nav bar */}
                <nav>
                    <ul>
                        <li id="nav-left"><Link to="/">Spencer Scher</Link></li>
                        <li className="links"><Link to="/resume">Resume</Link></li>
                        <li className="links"><Link to="/">About</Link></li>
                        <li className="links"><Link to="/">Home</Link></li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default nav;