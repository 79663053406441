import React, { Component } from 'react';
import './connect.css';

import { ReactComponent as Linkedin } from '../../assets/linkedin.svg';
import { ReactComponent as Instagram } from '../../assets/instagram.svg';
import { ReactComponent as Github } from '../../assets/github.svg';
import { ReactComponent as Email } from '../../assets/emailBlack.svg';
// import Button from '../button2';
import { Link } from 'react-router-dom';

class connect extends Component {
    render() {
        return (
            <div>
                <h1>Connect</h1>
                <div className="grid-container-buttons">
                    
                    <div className="grid-items-buttons">
                        <a href="https://www.linkedin.com/in/spencerscher/" target="_blank" className="button2"><Linkedin className="button2" /></a>
                        <a href="https://github.com/spencerscher" target="_blank" className="button2"><Github className="button2" /></a>
                        <a href="https://instagram.com/scherthemoment" target="_blank" className="button2"><Instagram className="button2" /></a>
                    </div>
                </div>
            </div >
        );
    }
}

export default connect;