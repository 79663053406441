import React from 'react'
import { Route, Routes } from 'react-router-dom'
// We will create these two pages in a moment
import HomePage from './views/Home/home'
import ResumePage from './views/Resume/resume.jsx';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-215769539-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage/>}/>
      {/* <Route path="/about" element={}/> */}
      <Route path="/resume" element={<ResumePage/>}/>
    </Routes>
  )
}