import React, { Component } from 'react';
import './snippets.css';
import { ReactComponent as Book } from '../../assets/book.svg';
import { ReactComponent as Location } from '../../assets/location.svg';
import { ReactComponent as Person } from '../../assets/person.svg';
import { ReactComponent as School } from '../../assets/school.svg';

var currentTime = new Date()

let year = currentTime.getFullYear();
year = year.toString().split(' ');
year = parseInt(year);
let age = year - 2000;


class snippets extends Component {
    
    render() {
        return (
            <div>
                <div className="grid-container-snippets">
                    <div className="grid-items-snippets">
                        <div>
                            <School className="icon" />
                            <br></br>
                            <span id="subtitle">Education</span>
                            <br></br>
                            <span id="main">University of Arizona</span>
                        </div>
                        <div>
                            <Location className="icon" />
                            <br></br>
                            <span id="subtitle">Location</span>
                            <br></br>
                            <span id="main">Washington, DC</span>
                        </div>
                        <div>
                            <Person className="icon" />
                            <br></br>
                            <span id="subtitle">Age</span>
                            <br></br>
                            <span id="main">{age}</span>
                        </div>
                        <div>
                            <Book className="icon" />
                            <br></br>
                            <span id="subtitle">Degree</span>
                            <br></br>
                            <span id="main">Electrical and Computer Engineering</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default snippets;
