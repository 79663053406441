import React, { Component } from 'react';
import './work.css';
import Button from '../../utils/GithubButton/button.jsx';

class work extends Component {
    render() {
        return (
            <div>
                <h1>Selected Work</h1>
                <div className="grid-container-work">
                    <div className="grid-items-work">
                        <a href="https://github.com/spencerscher/Smart-Home-Dashboard" target="_blank"><Button name="Smart Hub"/></a>
                        <a href="https://github.com/spencerscher/Silent-Auction" target="_blank"><Button name="Silent Auction"/></a>
                        <a href="https://github.com/spencerscher/nft-asset-api" target="_blank"><Button name="NFT Asset API"/></a>
                        <a href="https://github.com/spencerscher/Apple-Store-Monitor" target="_blank"><Button name="Apple Monitor"/></a>
                    </div>
                </div>
            </div>
        );
    }
}

export default work;